"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildReportDataForOperationalKPIInstall = exports.buildReportDataForOperationalKPIService = void 0;
const operationalKPIReportHelper_1 = require("./operationalKPIReportHelper");
function buildReportDataForOperationalKPIService(dataTotals, numberOfMonths) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84, _85, _86, _87, _88, _89, _90, _91, _92, _93, _94, _95, _96, _97, _98, _99, _100, _101, _102, _103, _104, _105, _106, _107, _108, _109, _110, _111, _112, _113, _114, _115, _116, _117, _118, _119, _120, _121, _122, _123, _124, _125, _126, _127, _128, _129, _130, _131, _132, _133, _134;
    const serviceObjectToReturn = {};
    serviceObjectToReturn.demandCallsPerMonth = {
        budget: (_a = dataTotals.budget.demandCallsPerMonth) !== null && _a !== void 0 ? _a : null,
        actual: (_b = dataTotals.actual.demandCallsPerMonth) !== null && _b !== void 0 ? _b : null,
        variance: (0, operationalKPIReportHelper_1.calcVariance)((_c = dataTotals.actual.demandCallsPerMonth) !== null && _c !== void 0 ? _c : null, (_d = dataTotals.budget.demandCallsPerMonth) !== null && _d !== void 0 ? _d : null),
    };
    // TP-1851: updating for reports
    serviceObjectToReturn.demandSystemCheckCallsPerMonth = {
        budget: (_e = dataTotals.budget.demandSystemCheckCallsPerMonth) !== null && _e !== void 0 ? _e : null,
        actual: (_f = dataTotals.actual.demandSystemCheckCallsPerMonth) !== null && _f !== void 0 ? _f : null,
        variance: (0, operationalKPIReportHelper_1.calcVariance)((_g = dataTotals.actual.demandSystemCheckCallsPerMonth) !== null && _g !== void 0 ? _g : null, (_h = dataTotals.budget.demandSystemCheckCallsPerMonth) !== null && _h !== void 0 ? _h : null),
    };
    // end of update
    serviceObjectToReturn.systemCheckCallsPerMonth = {
        budget: (_j = dataTotals.budget.systemCheckCallsPerMonth) !== null && _j !== void 0 ? _j : null,
        actual: (_k = dataTotals.actual.systemCheckCallsPerMonth) !== null && _k !== void 0 ? _k : null,
        variance: (0, operationalKPIReportHelper_1.calcVariance)((_l = dataTotals.actual.systemCheckCallsPerMonth) !== null && _l !== void 0 ? _l : null, (_m = dataTotals.budget.systemCheckCallsPerMonth) !== null && _m !== void 0 ? _m : null),
    };
    serviceObjectToReturn.techStaffing = {
        budget: ((_o = dataTotals.budget.techStaffing) !== null && _o !== void 0 ? _o : 0) / numberOfMonths,
        actual: ((_p = dataTotals.actual.techStaffing) !== null && _p !== void 0 ? _p : 0) / numberOfMonths,
        variance: ((_q = dataTotals.actual.techStaffing) !== null && _q !== void 0 ? _q : 0) / numberOfMonths -
            ((_r = dataTotals.budget.techStaffing) !== null && _r !== void 0 ? _r : 0) / numberOfMonths,
    };
    serviceObjectToReturn.callsPerTechPerDay = {
        budget: (0, operationalKPIReportHelper_1.calcCallsPerTechPerDay)((_s = dataTotals.budget.demandCallsPerMonth) !== null && _s !== void 0 ? _s : null, (_t = dataTotals.budget.demandSystemCheckCallsPerMonth) !== null && _t !== void 0 ? _t : null, (_u = dataTotals.budget.systemCheckCallsPerMonth) !== null && _u !== void 0 ? _u : null, ((_v = dataTotals.budget.techStaffing) !== null && _v !== void 0 ? _v : 0) / numberOfMonths, dataTotals.workingDays),
        actual: (0, operationalKPIReportHelper_1.calcCallsPerTechPerDay)((_w = dataTotals.actual.demandCallsPerMonth) !== null && _w !== void 0 ? _w : null, (_x = dataTotals.actual.demandSystemCheckCallsPerMonth) !== null && _x !== void 0 ? _x : null, (_y = dataTotals.actual.systemCheckCallsPerMonth) !== null && _y !== void 0 ? _y : null, ((_z = dataTotals.actual.techStaffing) !== null && _z !== void 0 ? _z : 0) / numberOfMonths, dataTotals.workingDays),
        variance: (0, operationalKPIReportHelper_1.calcVariance)((0, operationalKPIReportHelper_1.calcCallsPerTechPerDay)((_0 = dataTotals.actual.demandCallsPerMonth) !== null && _0 !== void 0 ? _0 : null, (_1 = dataTotals.actual.demandSystemCheckCallsPerMonth) !== null && _1 !== void 0 ? _1 : null, (_2 = dataTotals.actual.systemCheckCallsPerMonth) !== null && _2 !== void 0 ? _2 : null, ((_3 = dataTotals.actual.techStaffing) !== null && _3 !== void 0 ? _3 : 0) / numberOfMonths, dataTotals.workingDays), (0, operationalKPIReportHelper_1.calcCallsPerTechPerDay)((_4 = dataTotals.budget.demandCallsPerMonth) !== null && _4 !== void 0 ? _4 : null, (_5 = dataTotals.budget.demandSystemCheckCallsPerMonth) !== null && _5 !== void 0 ? _5 : null, (_6 = dataTotals.budget.systemCheckCallsPerMonth) !== null && _6 !== void 0 ? _6 : null, ((_7 = dataTotals.budget.techStaffing) !== null && _7 !== void 0 ? _7 : 0) / numberOfMonths, dataTotals.workingDays)),
    };
    serviceObjectToReturn.numberOfDemandTechLeads = {
        budget: (_8 = dataTotals.budget.numberOfDemandTechLeads) !== null && _8 !== void 0 ? _8 : null,
        actual: (_9 = dataTotals.actual.numberOfDemandTechLeads) !== null && _9 !== void 0 ? _9 : null,
        variance: (0, operationalKPIReportHelper_1.calcVariance)((_10 = dataTotals.actual.numberOfDemandTechLeads) !== null && _10 !== void 0 ? _10 : null, (_11 = dataTotals.budget.numberOfDemandTechLeads) !== null && _11 !== void 0 ? _11 : null),
    };
    // TP-1851: updating for reports
    serviceObjectToReturn.numberOfDemandSystemCheckTechLeads = {
        budget: (_12 = dataTotals.budget.numberOfDemandSystemCheckTechLeads) !== null && _12 !== void 0 ? _12 : null,
        actual: (_13 = dataTotals.actual.numberOfDemandSystemCheckTechLeads) !== null && _13 !== void 0 ? _13 : null,
        variance: (0, operationalKPIReportHelper_1.calcVariance)((_14 = dataTotals.actual.numberOfDemandSystemCheckTechLeads) !== null && _14 !== void 0 ? _14 : null, (_15 = dataTotals.budget.numberOfDemandSystemCheckTechLeads) !== null && _15 !== void 0 ? _15 : null),
    };
    // end of update
    serviceObjectToReturn.numberOfSystemCheckTechLeads = {
        budget: (_16 = dataTotals.budget.numberOfSystemCheckTechLeads) !== null && _16 !== void 0 ? _16 : null,
        actual: (_17 = dataTotals.actual.numberOfSystemCheckTechLeads) !== null && _17 !== void 0 ? _17 : null,
        variance: (0, operationalKPIReportHelper_1.calcVariance)((_18 = dataTotals.actual.numberOfSystemCheckTechLeads) !== null && _18 !== void 0 ? _18 : null, (_19 = dataTotals.budget.numberOfSystemCheckTechLeads) !== null && _19 !== void 0 ? _19 : null),
    };
    serviceObjectToReturn.demandCallsPercentLeadsGenerated = {
        budget: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_20 = dataTotals.budget.demandCallsPerMonth) !== null && _20 !== void 0 ? _20 : null, (_21 = dataTotals.budget.numberOfDemandTechLeads) !== null && _21 !== void 0 ? _21 : null),
        actual: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_22 = dataTotals.actual.demandCallsPerMonth) !== null && _22 !== void 0 ? _22 : null, (_23 = dataTotals.actual.numberOfDemandTechLeads) !== null && _23 !== void 0 ? _23 : null),
        variance: (0, operationalKPIReportHelper_1.calcVariance)((0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_24 = dataTotals.actual.demandCallsPerMonth) !== null && _24 !== void 0 ? _24 : null, (_25 = dataTotals.actual.numberOfDemandTechLeads) !== null && _25 !== void 0 ? _25 : null), (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_26 = dataTotals.budget.demandCallsPerMonth) !== null && _26 !== void 0 ? _26 : null, (_27 = dataTotals.budget.numberOfDemandTechLeads) !== null && _27 !== void 0 ? _27 : null)),
    };
    // TP-1851: updating for reports
    serviceObjectToReturn.demandSystemCheckCallsPercentLeadsGenerated = {
        budget: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_28 = dataTotals.budget.demandSystemCheckCallsPerMonth) !== null && _28 !== void 0 ? _28 : null, (_29 = dataTotals.budget.numberOfDemandSystemCheckTechLeads) !== null && _29 !== void 0 ? _29 : null),
        actual: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_30 = dataTotals.actual.demandSystemCheckCallsPerMonth) !== null && _30 !== void 0 ? _30 : null, (_31 = dataTotals.actual.numberOfDemandSystemCheckTechLeads) !== null && _31 !== void 0 ? _31 : null),
        variance: (0, operationalKPIReportHelper_1.calcVariance)((0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_32 = dataTotals.actual.demandSystemCheckCallsPerMonth) !== null && _32 !== void 0 ? _32 : null, (_33 = dataTotals.actual.numberOfDemandSystemCheckTechLeads) !== null && _33 !== void 0 ? _33 : null), (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_34 = dataTotals.budget.demandSystemCheckCallsPerMonth) !== null && _34 !== void 0 ? _34 : null, (_35 = dataTotals.budget.numberOfDemandSystemCheckTechLeads) !== null && _35 !== void 0 ? _35 : null)),
    };
    // end of update
    serviceObjectToReturn.systemCheckCallsPercentLeadsGenerated = {
        budget: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_36 = dataTotals.budget.systemCheckCallsPerMonth) !== null && _36 !== void 0 ? _36 : null, (_37 = dataTotals.budget.numberOfSystemCheckTechLeads) !== null && _37 !== void 0 ? _37 : null),
        actual: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_38 = dataTotals.actual.systemCheckCallsPerMonth) !== null && _38 !== void 0 ? _38 : null, (_39 = dataTotals.actual.numberOfSystemCheckTechLeads) !== null && _39 !== void 0 ? _39 : null),
        variance: (0, operationalKPIReportHelper_1.calcVariance)((0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_40 = dataTotals.actual.systemCheckCallsPerMonth) !== null && _40 !== void 0 ? _40 : null, (_41 = dataTotals.actual.numberOfSystemCheckTechLeads) !== null && _41 !== void 0 ? _41 : null), (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)((_42 = dataTotals.budget.systemCheckCallsPerMonth) !== null && _42 !== void 0 ? _42 : null, (_43 = dataTotals.budget.numberOfSystemCheckTechLeads) !== null && _43 !== void 0 ? _43 : null)),
    };
    serviceObjectToReturn.totalPercentLeadsGenerated = {
        budget: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(((_44 = dataTotals.budget.demandCallsPerMonth) !== null && _44 !== void 0 ? _44 : 0) +
            ((_45 = dataTotals.budget.systemCheckCallsPerMonth) !== null && _45 !== void 0 ? _45 : 0), ((_46 = dataTotals.budget.numberOfDemandTechLeads) !== null && _46 !== void 0 ? _46 : 0) +
            ((_47 = dataTotals.budget.numberOfSystemCheckTechLeads) !== null && _47 !== void 0 ? _47 : 0)),
        actual: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(((_48 = dataTotals.actual.demandCallsPerMonth) !== null && _48 !== void 0 ? _48 : 0) +
            ((_49 = dataTotals.actual.systemCheckCallsPerMonth) !== null && _49 !== void 0 ? _49 : 0), ((_50 = dataTotals.actual.numberOfDemandTechLeads) !== null && _50 !== void 0 ? _50 : 0) +
            ((_51 = dataTotals.actual.numberOfSystemCheckTechLeads) !== null && _51 !== void 0 ? _51 : 0)),
        variance: (0, operationalKPIReportHelper_1.calcVariance)((0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(((_52 = dataTotals.actual.demandCallsPerMonth) !== null && _52 !== void 0 ? _52 : 0) +
            ((_53 = dataTotals.actual.systemCheckCallsPerMonth) !== null && _53 !== void 0 ? _53 : 0), ((_54 = dataTotals.actual.numberOfDemandTechLeads) !== null && _54 !== void 0 ? _54 : 0) +
            ((_55 = dataTotals.actual.numberOfSystemCheckTechLeads) !== null && _55 !== void 0 ? _55 : 0)), (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(((_56 = dataTotals.budget.demandCallsPerMonth) !== null && _56 !== void 0 ? _56 : 0) +
            ((_57 = dataTotals.budget.systemCheckCallsPerMonth) !== null && _57 !== void 0 ? _57 : 0), ((_58 = dataTotals.budget.numberOfDemandTechLeads) !== null && _58 !== void 0 ? _58 : 0) +
            ((_59 = dataTotals.budget.numberOfSystemCheckTechLeads) !== null && _59 !== void 0 ? _59 : 0))),
    };
    serviceObjectToReturn.conversionRateDemandCalls = {
        budget: ((_60 = dataTotals.budget.conversionRateDemandCalls) !== null && _60 !== void 0 ? _60 : 0) / numberOfMonths,
        actual: ((_61 = dataTotals.actual.conversionRateDemandCalls) !== null && _61 !== void 0 ? _61 : 0) / numberOfMonths,
        variance: (0, operationalKPIReportHelper_1.calcVariance)(((_62 = dataTotals.actual.conversionRateDemandCalls) !== null && _62 !== void 0 ? _62 : 0) / numberOfMonths, ((_63 = dataTotals.budget.conversionRateDemandCalls) !== null && _63 !== void 0 ? _63 : 0) / numberOfMonths),
    };
    serviceObjectToReturn.averageSaleDemandCalls = {
        budget: ((_64 = dataTotals.budget.averageSaleDemandCalls) !== null && _64 !== void 0 ? _64 : 0) / numberOfMonths,
        actual: ((_65 = dataTotals.actual.averageSaleDemandCalls) !== null && _65 !== void 0 ? _65 : 0) / numberOfMonths,
        variance: (0, operationalKPIReportHelper_1.calcVariance)(((_66 = dataTotals.actual.averageSaleDemandCalls) !== null && _66 !== void 0 ? _66 : 0) / numberOfMonths, ((_67 = dataTotals.budget.averageSaleDemandCalls) !== null && _67 !== void 0 ? _67 : 0) / numberOfMonths),
    };
    serviceObjectToReturn.monthlyDemandSales = {
        budget: (_68 = dataTotals.budget.monthlyDemandSales) !== null && _68 !== void 0 ? _68 : null,
        actual: dataTotals.actual.demandCallsPerMonth *
            (serviceObjectToReturn.conversionRateDemandCalls.actual / 100) *
            serviceObjectToReturn.averageSaleDemandCalls.actual,
    };
    serviceObjectToReturn.monthlyDemandSales.variance = (0, operationalKPIReportHelper_1.calcVariance)(serviceObjectToReturn.monthlyDemandSales.actual, serviceObjectToReturn.monthlyDemandSales.budget);
    // TP-1851: updating for reports
    serviceObjectToReturn.conversionRateDemandSystemCheckCalls = {
        budget: ((_69 = dataTotals.budget.conversionRateDemandSystemCheckCalls) !== null && _69 !== void 0 ? _69 : 0) /
            numberOfMonths,
        actual: ((_70 = dataTotals.actual.conversionRateDemandSystemCheckCalls) !== null && _70 !== void 0 ? _70 : 0) /
            numberOfMonths,
    };
    serviceObjectToReturn.conversionRateDemandSystemCheckCalls.variance =
        (0, operationalKPIReportHelper_1.calcVariance)(serviceObjectToReturn.conversionRateDemandSystemCheckCalls.actual, serviceObjectToReturn.conversionRateDemandSystemCheckCalls.budget);
    serviceObjectToReturn.averageSaleDemandSystemCheckCalls = {
        budget: ((_71 = dataTotals.budget.averageSaleDemandSystemCheckCalls) !== null && _71 !== void 0 ? _71 : 0) /
            numberOfMonths,
        actual: ((_72 = dataTotals.actual.averageSaleDemandSystemCheckCalls) !== null && _72 !== void 0 ? _72 : 0) /
            numberOfMonths,
    };
    serviceObjectToReturn.averageSaleDemandSystemCheckCalls.variance =
        (0, operationalKPIReportHelper_1.calcVariance)(serviceObjectToReturn.averageSaleDemandSystemCheckCalls.actual, serviceObjectToReturn.averageSaleDemandSystemCheckCalls.budget);
    serviceObjectToReturn.monthlyDemandSystemCheckSales = {
        budget: (_73 = dataTotals.budget.monthlyDemandSystemCheckSales) !== null && _73 !== void 0 ? _73 : 0,
        actual: ((_74 = dataTotals.actual.demandSystemCheckCallsPerMonth) !== null && _74 !== void 0 ? _74 : 0) *
            (serviceObjectToReturn.conversionRateDemandSystemCheckCalls.actual /
                100) *
            serviceObjectToReturn.averageSaleDemandSystemCheckCalls.actual,
    };
    serviceObjectToReturn.monthlyDemandSystemCheckSales.variance = (0, operationalKPIReportHelper_1.calcVariance)(serviceObjectToReturn.monthlyDemandSystemCheckSales.actual, serviceObjectToReturn.monthlyDemandSystemCheckSales.budget);
    // TP-1851: End update
    serviceObjectToReturn.conversionRateSystemCheckCalls = {
        budget: ((_75 = dataTotals.budget.conversionRateSystemCheckCalls) !== null && _75 !== void 0 ? _75 : 0) / numberOfMonths,
        actual: ((_76 = dataTotals.actual.conversionRateSystemCheckCalls) !== null && _76 !== void 0 ? _76 : 0) / numberOfMonths,
    };
    serviceObjectToReturn.conversionRateSystemCheckCalls.variance = (0, operationalKPIReportHelper_1.calcVariance)(serviceObjectToReturn.conversionRateSystemCheckCalls.actual, serviceObjectToReturn.conversionRateSystemCheckCalls.budget);
    serviceObjectToReturn.averageSaleSystemCheckCalls = {
        budget: ((_77 = dataTotals.budget.averageSaleSystemCheckCalls) !== null && _77 !== void 0 ? _77 : 0) / numberOfMonths,
        actual: ((_78 = dataTotals.actual.averageSaleSystemCheckCalls) !== null && _78 !== void 0 ? _78 : 0) / numberOfMonths,
    };
    serviceObjectToReturn.averageSaleSystemCheckCalls.variance = (0, operationalKPIReportHelper_1.calcVariance)(serviceObjectToReturn.averageSaleSystemCheckCalls.actual, serviceObjectToReturn.averageSaleSystemCheckCalls.budget);
    serviceObjectToReturn.monthlySalesSystemCheckCalls = {
        budget: (_79 = dataTotals.budget.monthlySalesSystemCheckCalls) !== null && _79 !== void 0 ? _79 : null,
        actual: dataTotals.actual.systemCheckCallsPerMonth *
            (serviceObjectToReturn.conversionRateSystemCheckCalls.actual / 100) *
            serviceObjectToReturn.averageSaleSystemCheckCalls.actual,
    };
    serviceObjectToReturn.monthlySalesSystemCheckCalls.variance = (0, operationalKPIReportHelper_1.calcVariance)(serviceObjectToReturn.monthlySalesSystemCheckCalls.actual, serviceObjectToReturn.monthlySalesSystemCheckCalls.budget);
    serviceObjectToReturn.revenueGeneratedFromCallPerformance = {
        budget: (_80 = dataTotals.budget.revenueGeneratedFromCallPerformance) !== null && _80 !== void 0 ? _80 : null,
        actual: ((_81 = serviceObjectToReturn.monthlySalesSystemCheckCalls.actual) !== null && _81 !== void 0 ? _81 : 0) +
            ((_82 = serviceObjectToReturn.monthlyDemandSales.actual) !== null && _82 !== void 0 ? _82 : 0) +
            ((_83 = serviceObjectToReturn.monthlyDemandSystemCheckSales.actual) !== null && _83 !== void 0 ? _83 : 0),
    };
    serviceObjectToReturn.revenueGeneratedFromCallPerformance.variance =
        (0, operationalKPIReportHelper_1.calcVariance)(serviceObjectToReturn.revenueGeneratedFromCallPerformance.actual, serviceObjectToReturn.revenueGeneratedFromCallPerformance.budget);
    serviceObjectToReturn.monthlyAgreementRevenue = {
        budget: (_84 = dataTotals.budget.monthlyAgreementRevenue) !== null && _84 !== void 0 ? _84 : null,
        actual: (_85 = dataTotals.actual.monthlyAgreementRevenue) !== null && _85 !== void 0 ? _85 : null,
        variance: null,
    };
    serviceObjectToReturn.totalRevenueForMonth = {
        budget: (_86 = dataTotals.budget.totalRevenueForMonth) !== null && _86 !== void 0 ? _86 : null,
        actual: serviceObjectToReturn.revenueGeneratedFromCallPerformance.actual +
            serviceObjectToReturn.monthlyAgreementRevenue.actual,
        variance: null,
    };
    serviceObjectToReturn.soldHoursPerMonthGoal = {
        budget: (_87 = dataTotals.budget.soldHoursPerMonthGoal) !== null && _87 !== void 0 ? _87 : null,
        actual: (_88 = dataTotals.actual.soldHoursPerMonthGoal) !== null && _88 !== void 0 ? _88 : null,
        variance: (0, operationalKPIReportHelper_1.calcVariance)((_89 = dataTotals.actual.soldHoursPerMonthGoal) !== null && _89 !== void 0 ? _89 : null, (_90 = dataTotals.budget.soldHoursPerMonthGoal) !== null && _90 !== void 0 ? _90 : null),
    };
    serviceObjectToReturn.totalPayrollHours = {
        budget: null,
        actual: ((_91 = dataTotals.actual.totalPayrollHours) !== null && _91 !== void 0 ? _91 : 0) / numberOfMonths,
        variance: null,
    };
    serviceObjectToReturn.billableHourEfficiency = {
        budget: ((_92 = dataTotals.budget.billableHourEfficiency) !== null && _92 !== void 0 ? _92 : 0) / numberOfMonths,
        actual: serviceObjectToReturn.totalPayrollHours.actual
            ? (((_93 = serviceObjectToReturn.soldHoursPerMonthGoal.actual) !== null && _93 !== void 0 ? _93 : 0) /
                serviceObjectToReturn.totalPayrollHours.actual) *
                100
            : 0,
    };
    serviceObjectToReturn.billableHourEfficiency.variance = (0, operationalKPIReportHelper_1.calcVariance)(serviceObjectToReturn.billableHourEfficiency.actual, serviceObjectToReturn.billableHourEfficiency.budget);
    serviceObjectToReturn.agreementRunRate = {
        budget: ((_94 = dataTotals.budget.agreementRunRate) !== null && _94 !== void 0 ? _94 : 0) / numberOfMonths,
        actual: ((_95 = dataTotals.actual.agreementRunRate) !== null && _95 !== void 0 ? _95 : 0) / numberOfMonths,
        variance: null,
    };
    serviceObjectToReturn.agreementRetention = {
        budget: ((_96 = dataTotals.budget.agreementRetention) !== null && _96 !== void 0 ? _96 : 0) / numberOfMonths,
        actual: ((_97 = dataTotals.actual.agreementRetention) !== null && _97 !== void 0 ? _97 : 0) / numberOfMonths,
        variance: null,
    };
    serviceObjectToReturn.numberSystemCheckOpportunityCalls = {
        budget: ((_98 = dataTotals.budget.numberSystemCheckOpportunityCalls) !== null && _98 !== void 0 ? _98 : 0) /
            numberOfMonths,
        actual: (_99 = dataTotals.actual.numberSystemCheckOpportunityCalls) !== null && _99 !== void 0 ? _99 : null,
        variance: (_101 = ((_100 = dataTotals.actual.numberSystemCheckOpportunityCalls) !== null && _100 !== void 0 ? _100 : null) -
            dataTotals.budget.numberSystemCheckOpportunityCalls) !== null && _101 !== void 0 ? _101 : null,
    };
    // TP-1851: updating for reports
    serviceObjectToReturn.numberDemandSystemCheckOpportunityCalls = {
        budget: ((_102 = dataTotals.budget.numberDemandSystemCheckOpportunityCalls) !== null && _102 !== void 0 ? _102 : null) /
            numberOfMonths,
        actual: (_103 = dataTotals.actual.numberDemandSystemCheckOpportunityCalls) !== null && _103 !== void 0 ? _103 : null,
        variance: ((_104 = dataTotals.actual.numberDemandSystemCheckOpportunityCalls) !== null && _104 !== void 0 ? _104 : 0) -
            ((_105 = dataTotals.budget.numberDemandSystemCheckOpportunityCalls) !== null && _105 !== void 0 ? _105 : 0),
    };
    // end of update
    serviceObjectToReturn.numberDemandOpportunityCalls = {
        budget: ((_106 = dataTotals.budget.numberDemandOpportunityCalls) !== null && _106 !== void 0 ? _106 : null) / numberOfMonths,
        actual: (_107 = dataTotals.actual.numberDemandOpportunityCalls) !== null && _107 !== void 0 ? _107 : null,
        variance: ((_108 = dataTotals.actual.numberDemandOpportunityCalls) !== null && _108 !== void 0 ? _108 : null) -
            ((_109 = dataTotals.budget.numberDemandOpportunityCalls) !== null && _109 !== void 0 ? _109 : null),
    };
    serviceObjectToReturn.percentDemandOpportunityCalls = {
        budget: ((_110 = dataTotals.budget.percentDemandOpportunityCalls) !== null && _110 !== void 0 ? _110 : null) /
            numberOfMonths,
        actual: (dataTotals.actual.demandCallsPerMonth
            ? ((_111 = dataTotals.actual.numberDemandOpportunityCalls) !== null && _111 !== void 0 ? _111 : null) /
                dataTotals.actual.demandCallsPerMonth
            : null) * 100,
        variance: (dataTotals.actual.demandCallsPerMonth
            ? ((_112 = dataTotals.actual.numberDemandOpportunityCalls) !== null && _112 !== void 0 ? _112 : null) /
                dataTotals.actual.demandCallsPerMonth
            : null) *
            100 -
            ((_113 = dataTotals.budget.percentDemandOpportunityCalls) !== null && _113 !== void 0 ? _113 : null),
    };
    serviceObjectToReturn.demandCallsGeneratedFromOpps = {
        budget: ((_114 = dataTotals.budget.demandCallsGeneratedFromOpps) !== null && _114 !== void 0 ? _114 : null) / numberOfMonths,
        actual: (dataTotals.actual.numberDemandOpportunityCalls
            ? ((_115 = dataTotals.actual.numberOfDemandTechLeads) !== null && _115 !== void 0 ? _115 : null) /
                dataTotals.actual.numberDemandOpportunityCalls
            : null) * 100,
        variance: (dataTotals.actual.numberDemandOpportunityCalls
            ? ((_116 = dataTotals.actual.numberOfDemandTechLeads) !== null && _116 !== void 0 ? _116 : null) /
                dataTotals.actual.numberDemandOpportunityCalls
            : null) *
            100 -
            ((_117 = dataTotals.budget.demandCallsGeneratedFromOpps) !== null && _117 !== void 0 ? _117 : null),
    };
    // TP-1851: updating for reports
    serviceObjectToReturn.percentDemandSystemCheckOpportunityCalls = {
        budget: ((_118 = dataTotals.budget.percentDemandSystemCheckOpportunityCalls) !== null && _118 !== void 0 ? _118 : 0) /
            numberOfMonths,
        actual: dataTotals.actual.demandSystemCheckCallsPerMonth
            ? (((_119 = dataTotals.actual.numberDemandSystemCheckOpportunityCalls) !== null && _119 !== void 0 ? _119 : 0) /
                dataTotals.actual.demandSystemCheckCallsPerMonth) *
                100
            : null,
        variance: (dataTotals.actual.demandSystemCheckCallsPerMonth
            ? (((_120 = dataTotals.actual.numberDemandSystemCheckOpportunityCalls) !== null && _120 !== void 0 ? _120 : 0) /
                dataTotals.actual.demandSystemCheckCallsPerMonth) *
                100
            : null) -
            ((_121 = dataTotals.budget.percentDemandSystemCheckOpportunityCalls) !== null && _121 !== void 0 ? _121 : 0),
    };
    serviceObjectToReturn.demandSystemCheckCallsGeneratedFromOpps = {
        budget: ((_122 = dataTotals.budget.demandSystemCheckCallsGeneratedFromOpps) !== null && _122 !== void 0 ? _122 : 0) /
            numberOfMonths,
        actual: (dataTotals.actual.numberDemandSystemCheckOpportunityCalls
            ? ((_123 = dataTotals.actual.numberOfDemandSystemCheckTechLeads) !== null && _123 !== void 0 ? _123 : 0) /
                dataTotals.actual.numberDemandSystemCheckOpportunityCalls
            : null) * 100,
        variance: (dataTotals.actual.numberDemandSystemCheckOpportunityCalls
            ? ((_124 = dataTotals.actual.numberOfDemandSystemCheckTechLeads) !== null && _124 !== void 0 ? _124 : 0) /
                ((_125 = dataTotals.actual.numberDemandSystemCheckOpportunityCalls) !== null && _125 !== void 0 ? _125 : 0)
            : null) *
            100 -
            ((_126 = dataTotals.budget.demandSystemCheckCallsGeneratedFromOpps) !== null && _126 !== void 0 ? _126 : 0),
    };
    // end of update
    serviceObjectToReturn.percentSystemCheckOpportunityCalls = {
        budget: ((_127 = dataTotals.budget.percentSystemCheckOpportunityCalls) !== null && _127 !== void 0 ? _127 : 0) /
            numberOfMonths,
        actual: (dataTotals.actual.systemCheckCallsPerMonth
            ? ((_128 = dataTotals.actual.numberSystemCheckOpportunityCalls) !== null && _128 !== void 0 ? _128 : 0) /
                dataTotals.actual.systemCheckCallsPerMonth
            : null) * 100,
        variance: (dataTotals.actual.systemCheckCallsPerMonth
            ? ((_129 = dataTotals.actual.numberSystemCheckOpportunityCalls) !== null && _129 !== void 0 ? _129 : 0) /
                dataTotals.actual.systemCheckCallsPerMonth
            : null) *
            100 -
            ((_130 = dataTotals.budget.percentSystemCheckOpportunityCalls) !== null && _130 !== void 0 ? _130 : 0),
    };
    serviceObjectToReturn.systemCheckCallsGeneratedFromOpps = {
        budget: ((_131 = dataTotals.budget.systemCheckCallsGeneratedFromOpps) !== null && _131 !== void 0 ? _131 : null) /
            numberOfMonths,
        actual: (dataTotals.actual.numberSystemCheckOpportunityCalls
            ? ((_132 = dataTotals.actual.numberOfSystemCheckTechLeads) !== null && _132 !== void 0 ? _132 : null) /
                dataTotals.actual.numberSystemCheckOpportunityCalls
            : null) * 100,
        variance: (dataTotals.actual.numberSystemCheckOpportunityCalls
            ? ((_133 = dataTotals.actual.numberOfSystemCheckTechLeads) !== null && _133 !== void 0 ? _133 : null) /
                dataTotals.actual.numberSystemCheckOpportunityCalls
            : null) *
            100 -
            ((_134 = dataTotals.budget.systemCheckCallsGeneratedFromOpps) !== null && _134 !== void 0 ? _134 : null),
    };
    return serviceObjectToReturn;
}
exports.buildReportDataForOperationalKPIService = buildReportDataForOperationalKPIService;
function buildReportDataForOperationalKPIInstall(dataTotals, numberOfMonths) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    const installObjectToReturn = {};
    // Tech Lead Performance
    installObjectToReturn.techGeneratedLeadsRan = {
        budget: dataTotals.budget.techGeneratedLeadsRan,
        actual: dataTotals.actual.techGeneratedLeadsRan,
    };
    installObjectToReturn.techGeneratedLeadsRan.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.techGeneratedLeadsRan.actual, installObjectToReturn.techGeneratedLeadsRan.budget);
    installObjectToReturn.numberJobsSoldOnTechLeads = {
        budget: dataTotals.budget.numberJobsSoldTechLeads,
        actual: dataTotals.actual.numberJobsSoldTechLeads,
    };
    installObjectToReturn.numberJobsSoldOnTechLeads.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.numberJobsSoldOnTechLeads.actual, installObjectToReturn.numberJobsSoldOnTechLeads.budget);
    installObjectToReturn.conversionRateTechLeads = {
        budget: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(installObjectToReturn.techGeneratedLeadsRan.budget, installObjectToReturn.numberJobsSoldOnTechLeads.budget),
        actual: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(installObjectToReturn.techGeneratedLeadsRan.actual, installObjectToReturn.numberJobsSoldOnTechLeads.actual),
    };
    installObjectToReturn.conversionRateTechLeads.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.conversionRateTechLeads.actual, installObjectToReturn.conversionRateTechLeads.budget);
    installObjectToReturn.averageSaleTechLeads = {
        budget: ((_a = dataTotals.budget.averageSaleTechLeads) !== null && _a !== void 0 ? _a : 0) / numberOfMonths,
        actual: ((_b = dataTotals.actual.averageSaleTechLeads) !== null && _b !== void 0 ? _b : 0) / numberOfMonths,
    };
    installObjectToReturn.averageSaleTechLeads.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.averageSaleTechLeads.actual, installObjectToReturn.averageSaleTechLeads.budget);
    installObjectToReturn.totalTechLeadRevenue = {
        budget: ((_c = dataTotals.budget.numberJobsSoldTechLeads) !== null && _c !== void 0 ? _c : 0) *
            ((_d = installObjectToReturn.averageSaleTechLeads.budget) !== null && _d !== void 0 ? _d : 0),
        actual: ((_e = dataTotals.actual.numberJobsSoldTechLeads) !== null && _e !== void 0 ? _e : 0) *
            ((_f = installObjectToReturn.averageSaleTechLeads.actual) !== null && _f !== void 0 ? _f : 0),
    };
    installObjectToReturn.totalTechLeadRevenue.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.totalTechLeadRevenue.actual, installObjectToReturn.totalTechLeadRevenue.budget);
    // Marketed Lead Performance
    installObjectToReturn.marketingGeneratedLeadsRan = {
        budget: dataTotals.budget.marketingLeadsRan,
        actual: dataTotals.actual.marketingLeadsRan,
    };
    installObjectToReturn.marketingGeneratedLeadsRan.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.marketingGeneratedLeadsRan.actual, installObjectToReturn.marketingGeneratedLeadsRan.budget);
    installObjectToReturn.numberJobsSoldOnMarketingLeads = {
        budget: dataTotals.budget.numberJobsSoldMarketingLeads,
        actual: dataTotals.actual.numberJobsSoldMarketingLeads,
    };
    installObjectToReturn.numberJobsSoldOnMarketingLeads.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.numberJobsSoldOnMarketingLeads.actual, installObjectToReturn.numberJobsSoldOnMarketingLeads.budget);
    installObjectToReturn.conversionRateMarketingLeads = {
        budget: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(installObjectToReturn.marketingGeneratedLeadsRan.budget, installObjectToReturn.numberJobsSoldOnMarketingLeads.budget),
        actual: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(installObjectToReturn.marketingGeneratedLeadsRan.actual, installObjectToReturn.numberJobsSoldOnMarketingLeads.actual),
    };
    installObjectToReturn.conversionRateMarketingLeads.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.conversionRateMarketingLeads.actual, installObjectToReturn.conversionRateMarketingLeads.budget);
    installObjectToReturn.averageSaleMarketingLeads = {
        budget: ((_g = dataTotals.budget.averageSaleMarketingLeads) !== null && _g !== void 0 ? _g : 0) / numberOfMonths,
        actual: ((_h = dataTotals.actual.averageSaleMarketingLeads) !== null && _h !== void 0 ? _h : 0) / numberOfMonths,
    };
    installObjectToReturn.averageSaleMarketingLeads.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.averageSaleMarketingLeads.actual, installObjectToReturn.averageSaleMarketingLeads.budget);
    installObjectToReturn.totalMarketingLeadRevenue = {
        budget: ((_j = dataTotals.budget.numberJobsSoldMarketingLeads) !== null && _j !== void 0 ? _j : 0) *
            ((_k = installObjectToReturn.averageSaleMarketingLeads.budget) !== null && _k !== void 0 ? _k : 0),
        actual: ((_l = dataTotals.actual.numberJobsSoldMarketingLeads) !== null && _l !== void 0 ? _l : 0) *
            ((_m = installObjectToReturn.averageSaleMarketingLeads.actual) !== null && _m !== void 0 ? _m : 0),
    };
    installObjectToReturn.totalMarketingLeadRevenue.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.totalMarketingLeadRevenue.actual, installObjectToReturn.totalMarketingLeadRevenue.budget);
    // Other Lead Performance
    installObjectToReturn.otherGeneratedLeadsRan = {
        budget: dataTotals.budget.otherLeadsRan,
        actual: dataTotals.actual.otherLeadsRan,
    };
    installObjectToReturn.otherGeneratedLeadsRan.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.otherGeneratedLeadsRan.actual, installObjectToReturn.otherGeneratedLeadsRan.budget);
    installObjectToReturn.numberJobsSoldOnOtherLeads = {
        budget: dataTotals.budget.numberJobsSoldOtherLeads,
        actual: dataTotals.actual.numberJobsSoldOtherLeads,
    };
    installObjectToReturn.numberJobsSoldOnOtherLeads.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.numberJobsSoldOnOtherLeads.actual, installObjectToReturn.numberJobsSoldOnOtherLeads.budget);
    installObjectToReturn.conversionRateOtherLeads = {
        budget: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(installObjectToReturn.otherGeneratedLeadsRan.budget, installObjectToReturn.numberJobsSoldOnOtherLeads.budget),
        actual: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(installObjectToReturn.otherGeneratedLeadsRan.actual, installObjectToReturn.numberJobsSoldOnOtherLeads.actual),
    };
    installObjectToReturn.conversionRateOtherLeads.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.conversionRateOtherLeads.actual, installObjectToReturn.conversionRateOtherLeads.budget);
    installObjectToReturn.averageSaleOtherLeads = {
        budget: ((_o = dataTotals.budget.averageSaleOtherLeads) !== null && _o !== void 0 ? _o : 0) / numberOfMonths,
        actual: ((_p = dataTotals.actual.averageSaleOtherLeads) !== null && _p !== void 0 ? _p : 0) / numberOfMonths,
    };
    installObjectToReturn.averageSaleOtherLeads.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.averageSaleOtherLeads.actual, installObjectToReturn.averageSaleOtherLeads.budget);
    installObjectToReturn.totalOtherLeadRevenue = {
        budget: ((_q = dataTotals.budget.numberJobsSoldOtherLeads) !== null && _q !== void 0 ? _q : 0) *
            ((_r = installObjectToReturn.averageSaleOtherLeads.budget) !== null && _r !== void 0 ? _r : 0),
        actual: ((_s = dataTotals.actual.numberJobsSoldOtherLeads) !== null && _s !== void 0 ? _s : 0) *
            ((_t = installObjectToReturn.averageSaleOtherLeads.actual) !== null && _t !== void 0 ? _t : 0),
    };
    installObjectToReturn.totalOtherLeadRevenue.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.totalOtherLeadRevenue.actual, installObjectToReturn.totalOtherLeadRevenue.budget);
    installObjectToReturn.salesLeadsRanPerMonth = {
        budget: installObjectToReturn.techGeneratedLeadsRan.budget +
            installObjectToReturn.marketingGeneratedLeadsRan.budget +
            installObjectToReturn.otherGeneratedLeadsRan.budget,
        actual: installObjectToReturn.techGeneratedLeadsRan.actual +
            installObjectToReturn.marketingGeneratedLeadsRan.actual +
            installObjectToReturn.otherGeneratedLeadsRan.actual,
    };
    installObjectToReturn.salesLeadsRanPerMonth.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.salesLeadsRanPerMonth.actual, installObjectToReturn.salesLeadsRanPerMonth.budget);
    installObjectToReturn.numberOfSalesStaff = {
        budget: ((_u = dataTotals.budget.numberOfSalesStaff) !== null && _u !== void 0 ? _u : 0) / numberOfMonths,
        actual: ((_v = dataTotals.actual.numberOfSalesStaff) !== null && _v !== void 0 ? _v : 0) / numberOfMonths,
    };
    // installObjectToReturn.numberOfSalesStaff.variance = calcVariance(installObjectToReturn.numberOfSalesStaff.actual, installObjectToReturn.numberOfSalesStaff.budget);
    installObjectToReturn.callsPerSalesPersonPerDay = {
        budget: installObjectToReturn.salesLeadsRanPerMonth.budget &&
            dataTotals.workingDays &&
            installObjectToReturn.numberOfSalesStaff.budget
            ? installObjectToReturn.salesLeadsRanPerMonth.budget /
                dataTotals.workingDays /
                installObjectToReturn.numberOfSalesStaff.budget
            : 0,
        actual: installObjectToReturn.salesLeadsRanPerMonth.actual &&
            dataTotals.workingDays &&
            installObjectToReturn.numberOfSalesStaff.actual
            ? installObjectToReturn.salesLeadsRanPerMonth.actual /
                dataTotals.workingDays /
                installObjectToReturn.numberOfSalesStaff.actual
            : 0,
    };
    // installObjectToReturn.callsPerSalesPersonPerDay.variance = calcVariance(installObjectToReturn.callsPerSalesPersonPerDay.actual, installObjectToReturn.callsPerSalesPersonPerDay.budget);
    installObjectToReturn.numberOfJobsSold = {
        budget: installObjectToReturn.numberJobsSoldOnTechLeads.budget +
            installObjectToReturn.numberJobsSoldOnMarketingLeads.budget +
            installObjectToReturn.numberJobsSoldOnOtherLeads.budget,
        actual: installObjectToReturn.numberJobsSoldOnTechLeads.actual +
            installObjectToReturn.numberJobsSoldOnMarketingLeads.actual +
            installObjectToReturn.numberJobsSoldOnOtherLeads.actual,
    };
    installObjectToReturn.numberOfJobsSold.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.numberOfJobsSold.actual, installObjectToReturn.numberOfJobsSold.budget);
    installObjectToReturn.conversionRate = {
        budget: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(installObjectToReturn.salesLeadsRanPerMonth.budget, installObjectToReturn.numberOfJobsSold.budget),
        actual: (0, operationalKPIReportHelper_1.calcLeadsGeneratedPercent)(installObjectToReturn.salesLeadsRanPerMonth.actual, installObjectToReturn.numberOfJobsSold.actual),
    };
    installObjectToReturn.conversionRate.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.conversionRate.actual, installObjectToReturn.conversionRate.budget);
    installObjectToReturn.monthlySales = {
        budget: installObjectToReturn.totalTechLeadRevenue.budget +
            installObjectToReturn.totalMarketingLeadRevenue.budget +
            installObjectToReturn.totalOtherLeadRevenue.budget,
        actual: installObjectToReturn.totalTechLeadRevenue.actual +
            installObjectToReturn.totalMarketingLeadRevenue.actual +
            installObjectToReturn.totalOtherLeadRevenue.actual,
    };
    installObjectToReturn.monthlySales.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.monthlySales.actual, installObjectToReturn.monthlySales.budget);
    installObjectToReturn.averageSale = {
        budget: installObjectToReturn.numberOfJobsSold.budget
            ? installObjectToReturn.monthlySales.budget /
                installObjectToReturn.numberOfJobsSold.budget
            : 0,
        actual: installObjectToReturn.numberOfJobsSold.actual
            ? installObjectToReturn.monthlySales.actual /
                installObjectToReturn.numberOfJobsSold.actual
            : 0,
    };
    installObjectToReturn.averageSale.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.averageSale.actual, installObjectToReturn.averageSale.budget);
    installObjectToReturn.neededInstallationStaffing = {
        budget: dataTotals.budget.neededInstallationStaffing / numberOfMonths,
        actual: dataTotals.actual.neededInstallationStaffing / numberOfMonths,
    };
    installObjectToReturn.neededInstallationStaffing.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.neededInstallationStaffing.actual, installObjectToReturn.neededInstallationStaffing.budget);
    installObjectToReturn.soldDaysInstalled = {
        budget: dataTotals.budget.soldDaysInstalled / numberOfMonths,
        actual: dataTotals.actual.soldDaysInstalled / numberOfMonths,
    };
    installObjectToReturn.soldDaysInstalled.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.soldDaysInstalled.actual, installObjectToReturn.soldDaysInstalled.budget);
    installObjectToReturn.numberOfJobsInstalled = {
        budget: null,
        actual: dataTotals.actual.numberOfJobsInstalled,
    };
    // installObjectToReturn.numberOfJobsInstalled.variance = calcVariance(installObjectToReturn.numberOfJobsInstalled.actual, installObjectToReturn.numberOfJobsInstalled.budget);
    installObjectToReturn.installedRevenue = {
        budget: null,
        actual: dataTotals.actual.installedRevenue,
    };
    // installObjectToReturn.installedRevenue.variance = calcVariance(installObjectToReturn.installedRevenue.actual, installObjectToReturn.installedRevenue.budget);
    installObjectToReturn.soldDayEfficiency = {
        budget: !installObjectToReturn.neededInstallationStaffing.budget
            ? 0
            : installObjectToReturn.soldDaysInstalled.budget /
                (installObjectToReturn.neededInstallationStaffing.budget *
                    dataTotals.workingDays),
        actual: !installObjectToReturn.neededInstallationStaffing.actual
            ? 0
            : installObjectToReturn.soldDaysInstalled.actual /
                (installObjectToReturn.neededInstallationStaffing.actual *
                    dataTotals.workingDays),
    };
    installObjectToReturn.soldDayEfficiency.variance = (0, operationalKPIReportHelper_1.calcVariance)(installObjectToReturn.soldDayEfficiency.actual, installObjectToReturn.soldDayEfficiency.budget);
    installObjectToReturn.startingBacklog = {
        budget: null,
        actual: 0,
    };
    // installObjectToReturn.startingBacklog.variance = calcVariance(installObjectToReturn.startingBacklog.actual, installObjectToReturn.startingBacklog.budget);
    installObjectToReturn.numberOfCancelledJobs = {
        budget: null,
        actual: dataTotals.actual.numberOfCancelledJobs,
    };
    // installObjectToReturn.numberOfCancelledJobs.variance = calcVariance(installObjectToReturn.numberOfCancelledJobs.actual, installObjectToReturn.numberOfCancelledJobs.budget);
    installObjectToReturn.valueOfCancelledJobs = {
        budget: null,
        actual: dataTotals.actual.valueOfCancelledJobs,
    };
    // installObjectToReturn.valueOfCancelledJobs.variance = calcVariance(installObjectToReturn.valueOfCancelledJobs.actual, installObjectToReturn.valueOfCancelledJobs.budget);
    installObjectToReturn.backlogAmount = {
        budget: null,
        actual: ((_w = installObjectToReturn.monthlySales.actual) !== null && _w !== void 0 ? _w : 0) -
            ((_x = installObjectToReturn.installedRevenue.actual) !== null && _x !== void 0 ? _x : 0) +
            (installObjectToReturn.startingBacklog.actual -
                ((_y = installObjectToReturn.valueOfCancelledJobs.actual) !== null && _y !== void 0 ? _y : 0)),
    };
    // installObjectToReturn.backlogAmount.variance = calcVariance(installObjectToReturn.backlogAmount.actual, installObjectToReturn.backlogAmount.budget);
    installObjectToReturn.backlogNumberOfJobs = {
        budget: null,
        actual: ((_z = installObjectToReturn.numberOfJobsInstalled.actual) !== null && _z !== void 0 ? _z : 0) -
            ((_0 = installObjectToReturn.numberOfJobsSold.actual) !== null && _0 !== void 0 ? _0 : 0) -
            ((_1 = installObjectToReturn.valueOfCancelledJobs.actual) !== null && _1 !== void 0 ? _1 : 0),
    };
    // installObjectToReturn.backlogNumberOfJobs.variance = calcVariance(installObjectToReturn.backlogNumberOfJobs.actual, installObjectToReturn.backlogNumberOfJobs.budget);
    installObjectToReturn.backlogPercentOfJobs = {
        budget: null,
        actual: installObjectToReturn.backlogNumberOfJobs.actual &&
            installObjectToReturn.numberOfJobsSold.actual
            ? (installObjectToReturn.backlogNumberOfJobs.actual /
                installObjectToReturn.numberOfJobsSold.actual) *
                100
            : 0,
    };
    // installObjectToReturn.backlogPercentOfJobs.variance = calcVariance(installObjectToReturn.backlogPercentOfJobs.actual, installObjectToReturn.backlogPercentOfJobs.budget);
    return installObjectToReturn;
}
exports.buildReportDataForOperationalKPIInstall = buildReportDataForOperationalKPIInstall;
