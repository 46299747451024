"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildServiceAgreementsOKPIData = exports.calcLeadsGeneratedPercent = exports.calcCallsPerTechPerDay = exports.calcVariance = void 0;
function calcVariance(actual, budget) {
    return (actual !== null && actual !== void 0 ? actual : 0) - (budget !== null && budget !== void 0 ? budget : 0);
}
exports.calcVariance = calcVariance;
function calcCallsPerTechPerDay(demandCalls, demandSystemCheckCalls, systemCheckCalls, techStaffing, workingDays) {
    if (!techStaffing || techStaffing === 0 || workingDays === 0)
        return 0;
    return (((demandCalls !== null && demandCalls !== void 0 ? demandCalls : 0) +
        (demandSystemCheckCalls !== null && demandSystemCheckCalls !== void 0 ? demandSystemCheckCalls : 0) +
        (systemCheckCalls !== null && systemCheckCalls !== void 0 ? systemCheckCalls : 0)) /
        (techStaffing !== null && techStaffing !== void 0 ? techStaffing : 0) /
        workingDays);
}
exports.calcCallsPerTechPerDay = calcCallsPerTechPerDay;
function calcLeadsGeneratedPercent(calls, leads) {
    if (!calls)
        return 0;
    return ((leads !== null && leads !== void 0 ? leads : 0) / calls) * 100;
}
exports.calcLeadsGeneratedPercent = calcLeadsGeneratedPercent;
// Used to be calcAverageSale but wasn't used so now its gone.
function buildServiceAgreementsOKPIData(serviceObjectToReturn, monthData) {
    var _a, _b, _c, _d, _e;
    const budgetCurrentTotalAgreements = monthData[monthData.length - 1].budget.currentTotalAgreements;
    const actualCurrentTotalAgreements = (_a = monthData[monthData.length - 1].actual.currentTotalAgreements) !== null && _a !== void 0 ? _a : 0;
    let actualAgreementsAdded = 0;
    let actualAgreementsLost = 0;
    let actualVarianceToGoal = 0;
    for (let i = 0; i < monthData.length; i++) {
        const agreementsAddedThisMonth = (_b = monthData[i].actual.agreementsAddedThisMonth) !== null && _b !== void 0 ? _b : 0;
        actualAgreementsAdded += agreementsAddedThisMonth;
        const agreementsLostThisMonth = ((_c = monthData[i].actual.currentTotalAgreements) !== null && _c !== void 0 ? _c : 0) -
            (((_d = monthData[i].budget.currentTotalAgreements) !== null && _d !== void 0 ? _d : 0) +
                agreementsAddedThisMonth);
        actualAgreementsLost += agreementsLostThisMonth !== null && agreementsLostThisMonth !== void 0 ? agreementsLostThisMonth : 0;
        const monthlyNetGain = agreementsAddedThisMonth - (agreementsLostThisMonth !== null && agreementsLostThisMonth !== void 0 ? agreementsLostThisMonth : 0);
        const monthlyVarianceToGoal = monthData[i].actual.currentTotalAgreements < 1
            ? 0
            : monthlyNetGain - monthData[i].budget.agreementsAddedThisMonth;
        actualVarianceToGoal += monthlyVarianceToGoal;
    }
    serviceObjectToReturn.currentTotalAgreements = {
        budget: budgetCurrentTotalAgreements,
        actual: actualCurrentTotalAgreements,
        variance: null,
    };
    serviceObjectToReturn.agreementsAddedThisMonth = {
        budget: null,
        actual: actualAgreementsAdded,
        variance: null,
    };
    serviceObjectToReturn.agreementsLostThisMonth = {
        budget: null,
        actual: actualAgreementsLost,
        variance: null,
    };
    // the dataTotals.budget.agreementsAddedThisMonth is the value that gets pulled out of workshop that is needed for this calc
    serviceObjectToReturn.netGain = {
        budget: null,
        actual: actualAgreementsAdded + actualAgreementsLost,
        variance: actualVarianceToGoal,
    };
    serviceObjectToReturn.ytdVarianceToGoal = {
        budget: null,
        actual: actualVarianceToGoal + ((_e = monthData[0].budget.ytdVarianceToGoal) !== null && _e !== void 0 ? _e : 0),
        variance: null,
    };
}
exports.buildServiceAgreementsOKPIData = buildServiceAgreementsOKPIData;
