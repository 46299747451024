"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToCSV = exports.cloneDeep = exports.isNumeric = exports.objectFieldValidator = exports.fieldCheck = exports.arrayIntersect = exports.arrayDiff = exports.safeFileName = exports.isNullish = exports.isNull = exports.empty = exports.isset = void 0;
/**
 * "" => true
 * "apple" => true
 * null => false
 * false => true
 * 0 => true
 * undefined => false
 * true => true
 * [] => true
 * {} => true
 * 123 => true
 * @param value
 */
function isset(value) {
    if (value === undefined || value === null)
        return false;
    return true;
}
exports.isset = isset;
/**
 * "" => true
 * "apple" => false
 * null => true
 * false => true
 * 0 => false
 * undefined => true
 * true => false
 * [] => true
 * {} => true
 * 123 => false
 * Obj made with built-in constructors return false (new date/string/number/boolean/RegExp/Function)
 * @param value
 */
function empty(value) {
    if (!value && value !== 0)
        return true;
    if (Array.isArray(value) && value.length === 0)
        return true;
    if (value &&
        typeof value === "object" &&
        Object.keys(value).length === 0 &&
        value.constructor === Object)
        return true;
    return false;
}
exports.empty = empty;
//KRJ: Need constructor check to account for constructors like JSDate that make objects that can return no keys
/**
 * "" => false
 * "apple" => false
 * null => true
 * false => false
 * 0 => false
 * undefined => true
 * true => false
 * [] => false
 * {} => false
 * 123 => false
 * @param value
 */
function isNull(value) {
    if (value === undefined || value === null)
        return true;
    return false;
}
exports.isNull = isNull;
/**
 * DIFFERENT THAN isNULL
 * "" => false
 * "apple" => false
 * null => true
 * false => false
 * 0 => false
 * undefined => true
 * true => false
 * [] => true
 * {} => true
 * 123 => false
 * @param value
 */
function isNullish(value) {
    return (value === undefined ||
        value === null ||
        (typeof value === "object" && !Object.keys(value).length) ||
        (Array.isArray(value) && !value.length));
}
exports.isNullish = isNullish;
function safeFileName(value) {
    let fileNameCompany = value.replaceAll(" ", "_");
    fileNameCompany = fileNameCompany.replaceAll("/", "");
    fileNameCompany = fileNameCompany.replaceAll("\\", "");
    fileNameCompany = fileNameCompany.replaceAll("?", "");
    fileNameCompany = fileNameCompany.replaceAll("%", "");
    fileNameCompany = fileNameCompany.replaceAll("*", "");
    fileNameCompany = fileNameCompany.replaceAll(":", "");
    fileNameCompany = fileNameCompany.replaceAll("|", "");
    fileNameCompany = fileNameCompany.replaceAll('"', "");
    fileNameCompany = fileNameCompany.replaceAll("<", "");
    fileNameCompany = fileNameCompany.replaceAll(">", "");
    fileNameCompany = fileNameCompany.replaceAll(".", "");
    fileNameCompany = fileNameCompany.replaceAll(",", "");
    fileNameCompany = fileNameCompany.replaceAll(";", "");
    fileNameCompany = fileNameCompany.replaceAll("=", "");
    return fileNameCompany;
}
exports.safeFileName = safeFileName;
/**
 * I added examples into this
 *
 * const a = [123, 234, 345, 456];
 * const b = [345, 456, 567, 678];
 *
 * to find what is in a and not in b
 * arrayDiff(a, b)
 *
 * to find what is in b and not a
 * arrayDiff(b, a)
 *
 * @param arr1
 * @param arr2
 */
function arrayDiff(arr1, arr2) {
    return arr1.filter((x) => !arr2.includes(x));
}
exports.arrayDiff = arrayDiff;
function arrayIntersect(arr1, arr2) {
    return arr1.filter((x) => arr2.includes(x));
}
exports.arrayIntersect = arrayIntersect;
/**
 MAKE SURE FIELD NAMES ARE SPELLED CORRECTLY
 - Checks validity of deeply nested objs to avoid throwing errors on null fields
 */
function fieldCheck(value, fields) {
    if (fields && fields.length && !empty(value) && typeof value === "object") {
        if (fields[0] in value && isset(value[fields[0]])) {
            const firstField = fields.shift();
            return fieldCheck(value[firstField], fields);
        }
        else {
            return false;
        }
    }
    return !isNullish(value);
}
exports.fieldCheck = fieldCheck;
/**
 *
 * @param obj
 * @param fieldArray
 * @param returnData
 * @returns value or null???
 * @summary set return type when calling objectFieldValidator<type>()
 */
function objectFieldValidator(obj, fieldArray, returnData) {
    if (obj) {
        let currentObj = obj;
        for (let i = 0; i < fieldArray.length; i++) {
            if (typeof currentObj === "object" &&
                !empty(currentObj) &&
                fieldArray[i] in currentObj) {
                currentObj = currentObj[fieldArray[i]];
            }
            else {
                currentObj = null;
                break;
            }
        }
        if (returnData) {
            return currentObj;
        }
        else {
            return !isNullish(currentObj);
        }
    }
}
exports.objectFieldValidator = objectFieldValidator;
function isNumeric(num) {
    if (typeof num === "string" && num.trim() !== "" && !isNaN(Number(num)))
        return true;
    else if (typeof num === "number" && !isNaN(num))
        return true;
    return false;
}
exports.isNumeric = isNumeric;
/**
 * This will create a full clone of an object that is not a memory pointer.
 * @param obj
 */
function cloneDeep(obj) {
    return JSON.parse(JSON.stringify(obj));
}
exports.cloneDeep = cloneDeep;
function convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr);
    return array
        .map((it) => {
        return Object.values(it).toString();
    })
        .join("\n");
}
exports.convertToCSV = convertToCSV;
